<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input
                  v-model="name"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Mobile"
              >
                <b-form-input
                  v-model="mobile"
                  placeholder="Enter Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email"
              >
                <b-form-input
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Type of Issue</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Task Type"
              >
                <v-select
                  v-model="tasktype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Bug', 'New Feature', 'Other']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Criticality</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Criticality"
              >
                <v-select
                  v-model="criticality"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['High', 'Medium', 'Low']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Module</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Module"
              >
                <v-select
                  v-model="moduleName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="[
                    'Dashboard',
                    'HR',
                    'Accounting',
                    'CRM',
                    'Master HR',
                    'Master Account',
                    'Master CRM',
                    'Reports',
                    'Setup',
                    'Other',
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Description</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Description"
              >
                <b-form-textarea
                  placeholder="Enter Description"
                  v-model="description"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 1</label>
              <b-form-file
                v-model="documents.attachment1"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'task', 'attachment1')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="documents.attachment1" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 2</label>
              <b-form-file
                v-model="documents.attachment2"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'task', 'attachment2')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="documents.attachment2" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 3</label>
              <b-form-file
                v-model="documents.attachment3"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'task', 'attachment3')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="documents.attachment3" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment 4</label>
              <b-form-file
                v-model="documents.attachment4"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'task', 'attachment4')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="documents.attachment4" />
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    BFormFile,
    Attachment,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      flag: false,
      documents: {
        attachment1: [],
        attachment2: [],
        attachment3: [],
        attachment4: [],
      },
      name: "",
      email: "",
      mobile: "",
      criticality: "",
      moduleName: "",
      description: "",
      tasktype: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const role = userData.role;
    if (role !== "admin") {
      this.$router.push("/");
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
    // this.name = userData.name;
    // this.email = userData.email;
    // this.mobile = userData.mobile;
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/issuelog/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.name = item.name;
        this.email = item.email;
        this.mobile = item.mobile;
        this.criticality = item.criticality;
        this.moduleName = item.moduleName;
        this.description = item.description;
        this.tasktype = item.tasktype;
        this.documents.attachment1 = item.attachment1;
        this.documents.attachment2 = item.attachment2;
        this.documents.attachment3 = item.attachment3;
        this.documents.attachment4 = item.attachment4;
      });
    },
    async handleChangeFile(e, type, name) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.documents[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"))
        .finally(()=>{
          this.flag = false;

        })
    },

    onClickBack() {
      this.$router.push("/setup/issuelog");
    },
    submitForm(e) {
      const data = {
        name: this.name,
        email: this.email,
        mobile: this.mobile,
        criticality: this.criticality,
        module: this.moduleName,
        description: this.description,
        type: this.tasktype,
        attachment1: this.documents.attachment1,
        attachment2: this.documents.attachment2,
        attachment3: this.documents.attachment3,
        attachment4: this.documents.attachment4,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {

        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          let fd= new FormData()
          fd.append('isuuetype',this.tasktype)
          fd.append('module',this.moduleName)
          fd.append('criticality',this.criticality)
          fd.append('description',this.description)
          fd.append('attachment1',this.documents.attachment1)
          fd.append('attachment2',this.documents.attachment2)
          fd.append('attachment3',this.documents.attachment3)
          fd.append('attachment4',this.documents.attachment4)

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: "post",
            url:`https://api.erp.nestoriagroup.com/supportapi/issuelog/create`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: fd,
          })
            .then((response) => {
              this.submitDisable = false;

              this.$router.push("/setup/issuelog");
              this.$swal({
                title: "Submited",
                text:"Created SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
